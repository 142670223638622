<template>
  <ClientOnly>
    <div class="report">
      <div class="events">
        <div 
          v-for="event in events" :key="event.title" class="container" 
          :class="{pointer: event.hasVideo, active: event.videoIndex === activeIndex}"
          @click="changeSlide(event)">
          <div class="event-box" >
            <v-icon :icon="event.icon" color="primary" class="mr-4" />
            <div class="font-subtitle">{{ $t(event.title) }}</div>
          </div>
        </div>
      </div>
      <div class="video-box">
        <swiper
          v-bind="{...options}"
          @swiper="getRef"
          @slideChange="onSwiper"
          class="video-slider">
          <swiper-slide
            v-for="video in videos" :key="video.id" style="padding:56.25% 0 0 0;position:relative;">
            <iframe :src="video.url" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </swiper-slide>
          <div class="swiper-pagination">
            <div v-for="video in videos" :key="video.id" class="swiper-pagination-bullet"></div>
          </div>
        </swiper>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
const videoSlider = ref<any>()

const options = {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  autoplay: {
    delay: 8000,
  },
}

const events = [
  { title: Words.HomeReportCaption1, icon: 'hotel_class', hasVideo: true, videoIndex: 0 },
  { title: Words.HomeReportCaption2, icon: 'hotel_class', hasVideo: false },
  { title: Words.HomeReportCaption3, icon: 'hotel_class', hasVideo: true, videoIndex: 1 },
  { title: Words.HomeReportCaption4, icon: 'hotel_class', hasVideo: false },
]

const videos = [
  { id: 'video1', url: 'https://player.vimeo.com/video/908149324?h=bad75c560a' },
  { id: 'video2', url: 'https://player.vimeo.com/video/487968780?h=75833fdf8e' },
]

const activeIndex = ref(0)
const getRef = (swiper: any) => {
  // 紀錄雷坑: 無法用 ref 拿到 swiper 中的 methods(沒有被exposed)
  // 利用 swiper event 拿到 swiper instance，就能順利使用 swiper methods 
  videoSlider.value = swiper
}
const onSwiper = (swiper: any) => {
  activeIndex.value = swiper.activeIndex
}

const changeSlide = (event: any) => {
  if (!event.hasVideo) return 
  videoSlider.value.slideTo(event.videoIndex)
}

</script>

<style lang="stylus" scoped>
.report
  +over(md)
    display flex
    gap 24px
    align-items flex-start
  .events
    +over(md)
      width 40%
  .container
    padding 0 12px
    &.pointer
      cursor pointer
    &.active
      background $black-0-06
      border-left 2px solid $primary
  .event-box
    display flex
    align-items center
    padding 16px 0
    border-bottom 1px solid $black-0-12
    +over(md)
      min-height 90px
  .video-box
    position relative
    +over(md)
      width 60%
    +under(md)
      max-width 576px
      margin 24px auto 0
.video-slider
  position static
  .swiper-pagination
    bottom -25px
  :deep .swiper-pagination-bullet-active
    background-color $primary
</style>